:root {
  --scrollbar-track: #4F7DBB66;
  --scrollbar-thumb: #4F7DBB;
  --scrollbar-thumb-hover: #436CA1;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Firefox styles */ 
html {
  scrollbar-width: "thin";
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}